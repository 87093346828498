.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.2rem 10%;
  // text-align: center;
  // width: 50%;
  // margin: 5% auto;
  // justify-content: center;
  // align-items: center;
  // border: 1px solid rgba(84,244,253,.2);
  .videoPlayer {
    // border: 1px solid brown;
  }
  .content {
    padding-top: 0.2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    .videoInfo {
      width: 100%;
      height: max-content;
      color: white;
      .title {
        font-size: 0.3rem;
        line-height: 0.3rem;
      }
      .time {
        font-size: 0.1rem;
        line-height: 0.1rem;
      }
    }
    .brief {
      padding-top: 0.1rem;
      font-size: 0.2rem;
      line-height: 0.2rem;
    }
    .moreVideoList {
      width: 30%;
      padding: 0.2rem;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.6);
      border-radius: 0.2rem;
      // border: 1px solid #54f4fd;

      .title {
        color: white;
        font-size: 0.24rem;
        line-height: 0.24rem;
      }
      .list {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        .singleCourse {
          display: flex;
          padding: 10px;

          .singleCourseImg {
            float: left;
            width: 40%;
            position: relative;
            img {
              width: 100%;
            }
            .imgAsideText {
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
          .singleCourseContent {
            float: left;
            margin-left: 5px;
            width: 50%;
            span {
              color: white;
            }
          }
        }
      }
    }
  }
}
