.container {
  justify-content: space-between;
  .form {
    width: 70vw;
    :global {
      .ant-form-item-label > label {
        color: white;
      }
    }
  }
  .submit {
  }
}
