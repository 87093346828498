html {
  font-size: calc(100vw / 19.2); // 设计稿是 1920px
  body {
    overflow-x: hidden;
    .basic-layout {
      background: linear-gradient(145deg, #3a78b2 0%, #1f466b 14%, #0e0303 100%);
      min-width: 100vw;
      min-height: 100vh;
      :global .ant-progress-circle .ant-progress-text {
        color: aliceblue;
      }
      .header {
        background: url('@/assets/basic/path.png');
        background-size: 100% 100%;
        width: 100%;
        margin-top: 0.16rem;
        height: 1.2rem;
        line-height: normal;
        padding: 0px;
        .title {
          height: 1.2rem;
          background: url('@/assets/basic/title.png') no-repeat center top; //隐去logo演示用
          background-size: 2.03rem 0.9rem;
          padding-top: 0.88rem;
          font-family: 'Krungthep';
          font-size: 0.14rem;
          text-align: center;
          color: #ffffff;
        }
        .left-box {
          float: left;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          height: 1.2rem;
          margin-top: -1.2rem;
          .tab {
            display: inline-block;
            width: 1.76rem;
            height: 0.64rem;
            line-height: 0.64rem;
            margin-left: 0.16rem;
            text-align: center;
            background: url('@/assets/basic/left_tab.png') no-repeat;
            background-size: 100% 100%;
            font-family: 'PingFangSC-Regular';
            font-size: 0.2rem;
            color: #e1fdff;
          }
          .tabundoleft {
            display: inline-block;
            width: 1.76rem;
            height: 0.64rem;
            line-height: 0.64rem;
            margin-left: 0.16rem;
            text-align: center;
            background: url('@/assets/basic/left_tab.png') no-repeat;
            background-size: 100% 100%;
            font-family: 'PingFangSC-Regular';
            font-size: 0.2rem;
            color: gray;
          }
          .tab-active,
          .tab:hover {
            text-decoration: none;
            background: url('@/assets/basic/active_left_tab.png') no-repeat left center;
            background-size: 100% 100%;
            color: #f8b902;
          }
        }
        .right-box {
          float: right;
          display: inline-flex;
          justify-content: flex-end;
          align-items: center;
          height: 1.2rem;
          margin-top: -1.2rem;
          .tab {
            display: inline-block;
            width: 1.76rem;
            height: 0.64rem;
            line-height: 0.64rem;
            margin-right: 0.16rem;
            text-align: center;
            background: url('@/assets/basic/right_tab.png') no-repeat;
            background-size: 100% 100%;
            font-family: 'PingFangSC-Regular';
            font-size: 0.2rem;
            color: #e1fdff;
          }
          .tabundo {
            display: inline-block;
            width: 1.76rem;
            height: 0.64rem;
            line-height: 0.64rem;
            margin-right: 0.16rem;
            text-align: center;
            background: url('@/assets/basic/right_tab.png') no-repeat;
            background-size: 100% 100%;
            font-family: 'PingFangSC-Regular';
            font-size: 0.2rem;
            color: gray;
          }
          .tab-active,
          .tab:hover {
            text-decoration: none;
            background: url('@/assets/basic/active_right_tab.png') no-repeat;
            background-size: 100% 100%;
            color: #f8b902;
          }
        }
      }
      .content {
        // height: calc(100vh - 1.36rem);
      }
    }
  }
}
:global {
  .bg {
    display: none;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.3);
    z-index: 1001;
    // -moz-opacity: 0.3;
    // opacity:.30;
    // filter: alpha(opacity=30);
  }
  .show {
    display: block;
    position: absolute;
    width: 100vw;
    height: auto;
    // background-color: white;
    z-index: 1002;
    overflow: auto;
  }
  .basic-progress {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 1003;
  }
}
