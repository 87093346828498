.desktop {
  height: 100%;
  .content {
    height: calc(100% - 0.08rem);
    margin: 0 0.2rem;
    width: calc(100% - 0.4rem);
    // background: url('@/assets/desktop_exercise/模拟监控系统.png') no-repeat;
    // background-size: 100%;
    transition: opacity 1s;
    .angle {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
    }
    .top-left {
      margin: -0.12rem 0 0 -0.17rem;
      background: url('@/assets/desktop_exercise/panel/左上.png');
      background-size: 100%;
    }
    .bottom-left {
      margin: calc(100% - 11.28rem) 0 0 -0.17rem;
      background: url('@/assets/desktop_exercise/panel/左下.png');
      background-size: 100%;
    }
    .top-right {
      margin: 0.48rem 0 0 calc(100% - 0.68rem);
      background: url('@/assets/desktop_exercise/panel/右上.png');
      background-size: 100%;
    }
    .bottom-right {
      margin: calc(100% - 11.28rem) 0 0 calc(100% - 0.68rem);
      background: url('@/assets/desktop_exercise/panel/右下.png');
      background-size: 100%;
    }
    .title {
      line-height: 0.6rem;
      // margin-left: 0.24rem;
      font-family: PingFangSC-Regular;
      font-size: 0.24rem;
      color: #54f4fd;
      text-shadow: 0 0 0.02rem #54f4fd;
    }
    .canvas-scroll {
      border: 1px solid rgba(84, 244, 253, 0.2);
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100% - 0.6rem);
      .progress {
        position: absolute;
        width: 13%;
        font-size: 14px;
        margin-left: 72%;
        color: #e1fdff;
        .timerContent {
          margin-top: 10%;
          font-weight: bold;
        }
        .progressBar {
          margin-top: 5%;
        }
      }
    }
    .canvas-scroll::-webkit-scrollbar {
      display: none;
    }
  }
  .contentPanel {
    height: calc(100% - 2.32rem);
    width: calc(100% - 0.4rem);
    margin: 0 0.2rem;
    position: absolute;
    top: 1.36rem;
    z-index: 20;
  }
  .tab-container {
    position: fixed;
    z-index: 100;
    height: 0.64rem;
    margin: 0.17rem 0 0 0.2rem;
    bottom: 0.2rem;
    .item {
      display: inline-flex;
      align-items: flex-end;
      width: 2.04rem;
      .icon-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.08rem;
        width: 0.96rem;
        height: 0.64rem;
        background: url('@/assets/desktop_exercise/tabs/边框.png');
        background-size: 100%;
        cursor: pointer;
        .data-monitor {
          background: url('@/assets/desktop_exercise/tabs/数据监控.png');
        }
        .floor-plan {
          background: url('@/assets/desktop_exercise/tabs/楼层平面图.png');
        }
        .param-info {
          background: url('@/assets/desktop_exercise/tabs/参数表信息.png');
        }
        .event-window {
          background: url('@/assets/desktop_exercise/tabs/事件窗口.png');
        }
        .fault-simulate {
          background: url('@/assets/desktop_exercise/tabs/故障模拟.png');
        }
        .expert-diagnosis {
          background: url('@/assets/desktop_exercise/tabs/专家诊断.png');
        }
        .system-help {
          background: url('@/assets/desktop_exercise/tabs/系统帮助.png');
        }
      }
      .icon-active,
      .icon-container:hover {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.08rem;
        width: 0.96rem;
        height: 0.64rem;
        background: url('@/assets/desktop_exercise/tabs/边框_active.png');
        background-size: 100%;
        cursor: pointer;
        .data-monitor {
          background: url('@/assets/desktop_exercise/tabs/数据监控_active.png');
        }
        .floor-plan {
          background: url('@/assets/desktop_exercise/tabs/楼层平面图_active.png');
        }
        .param-info {
          background: url('@/assets/desktop_exercise/tabs/参数表信息_active.png');
        }
        .event-window {
          background: url('@/assets/desktop_exercise/tabs/事件窗口_active.png');
        }
        .fault-simulate {
          background: url('@/assets/desktop_exercise/tabs/故障模拟_active.png');
        }
        .expert-diagnosis {
          background: url('@/assets/desktop_exercise/tabs/专家诊断_active.png');
        }
        .system-help {
          background: url('@/assets/desktop_exercise/tabs/系统帮助_active.png');
        }
      }
      .text {
        font-size: 0.2rem;
        color: #e1fdff;
        margin-bottom: -0.04rem;
      }
    }
  }
}
