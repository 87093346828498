input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0 60px white inset;
  -webkit-text-fill-color: #878787;
}

.container {
  display: flex;
  margin: 0px;
  padding: 0px;
  height: 85vh;
  width: 100vw;
  z-index: 1002;
  // background: url('@/assets/login/back.png') no-repeat;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  .board {
    width: 10.3rem;
    height: 7rem;
    background: url('@/assets/login/board.png') no-repeat;
    background-size: 100% 100%;
    .login {
      width: 4.4rem;
      height: 6.81rem;
      margin-right: 0.19rem;
      padding: 1.34rem 0.65rem 0rem;
      float: right;
      .title {
        font-family: 'PingFangSC-Semibold';
        font-size: 0.4rem;
        margin-bottom: 0.8rem;
        color: #666666;
      }
      .content {
        margin-top: 0.7rem;
        font-family: 'PingFangSC-Regular';
        .name {
          display: block;
          font-size: 0.2rem;
          color: #666;
        }
        :global {
          .ant-input {
            border-width: 0 0 1px 0;
            border-color: #d8d8d8;
            font-size: 0.18rem;
            padding-left: 0px;
            color: #333;
            &:hover {
              border-right-width: 0 !important;
              border-bottom-color: #f8b902;
            }
            &:focus {
              box-shadow: 0 0 0 60px white inset;
              border-right-width: 0 !important;
              border-bottom-color: #f8b902;
            }
          }
          .ant-input:not(.ant-input-disabled) {
            border-right-width: 0 !important;
          }
        }
      }
      .tip {
        display: flex;
        justify-content: space-between;
        margin-top: 0.14rem;
        font-family: 'PingFangSC-Regular';
        font-size: 0.14rem;
        color: #999999;
        .check {
          font-size: 0.14rem;
          color: #999;
          :global {
            .ant-checkbox + span {
              padding-left: 0.04rem;
            }
            .ant-checkbox-inner {
              height: 0.14rem;
              width: 0.14rem;
              &:after {
                width: 0.055rem;
                height: 0.068rem;
              }
            }
          }
        }
      }
      .confirm {
        margin-top: 0.3rem;
        border-radius: 2px;
        width: 100%;
        height: 0.44rem;
        line-height: 0.44rem;
      }
      .registry {
        text-align: center;
        margin-top: 0.84rem;
        font-size: 0.14rem;
        color: #999;
      }
    }
  }
}
