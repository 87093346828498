.crcode {
  margin: 25vh auto 0;
  text-align: center;
}

.code-text {
  margin-top: 2vh;
  text-align: center;
  width: 100vw;
  position: relative;
}

.code-text span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  background-color: white;
  border-radius: 4px;
  line-height: 3vh;
  width: 300px;
}
