.container {
  .modelForm {
    :global {
      .ant-form-item-label > label {
        // color: white !important;
      }
      .ant-input {
        border: 1px solid black;
        color: black;
      }

      .ant-input-group-addon {
        border: 1px solid black;
        border-right: none;
      }
    }
  }
}
