.container {
  margin: 0.36rem 0 0 0.22rem;
  padding: 0rem 0.24rem 0.24rem 0.24rem;
  width: 6.31rem;
  height: 7.17rem;
  background: url('@/assets/desktop_exercise/panel/参数表信息.png') no-repeat;
  background-size: 100%;
  .angle {
    position: absolute;
    width: 0.35rem;
    height: 0.35rem;
  }
  .top-left {
    margin: -0.1rem 0 0 -0.37rem;
    background: url('@/assets/desktop_exercise/panel/左上.png');
    background-size: 100%;
  }
  .bottom-left {
    margin: 6.92rem 0 0 -0.37rem;
    background: url('@/assets/desktop_exercise/panel/左下.png');
    background-size: 100%;
  }
  .top-right {
    margin: 0.46rem 0 0 5.8rem;
    background: url('@/assets/desktop_exercise/panel/右上.png');
    background-size: 100%;
  }
  .bottom-right {
    margin: 6.92rem 0 0 5.8rem;
    background: url('@/assets/desktop_exercise/panel/右下.png');
    background-size: 100%;
  }
  .title {
    margin: 0.12rem 0;
    font-family: PingFangSC-Regular;
    font-size: 0.24rem;
    color: #54f4fd;
    text-shadow: 0 0 0.02rem #54f4fd;
  }
  .panel {
    :global {
      .ant-collapse-header {
        font-family: PingFangSC-Regular;
        font-size: 0.2rem;
        color: #e1fdff;
        border-bottom: 1px solid #e1fdff;
        padding: 0.16rem 40px 0.05rem 0;
      }
      .ant-collapse-content-box {
        background-color: rgba(225, 253, 255, 0.04);
        padding: 0.16rem;
        max-height: 2.8rem;
        overflow-y: scroll;
      }
    }
    .content {
      color: #e1fdff;
      font-size: 0.14rem;
      .btn {
        background: initial;
        color: #f8b902;
        border: 1px solid #f8b902;
        box-shadow: inset 0 0 4px 0 rgba(248, 185, 2, 0.8);
      }
      .param-row {
        line-height: 0.32rem;
        margin: 0.06rem 0;
        .item {
          display: flex;
          align-items: center;
          line-height: 0.32rem;
          height: 0.32rem;
          :global {
            .ant-form-item {
              margin-bottom: 0px;
              height: 0.32rem;
              display: flex;
              .ant-form-item-label {
                line-height: 0.32rem;
                & > label {
                  color: #e1fdff;
                  font-size: 0.14rem;
                }
              }
              .ant-form-item-control {
                line-height: 0.32rem;
                .ant-input {
                  font-size: 0.14rem;
                }
                .ant-input-suffix {
                  font-size: 0.14rem;
                  right: 0.06rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
