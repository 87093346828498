.container {
  margin-top: 0.36rem;
  padding: 0rem 0.24rem 0.24rem 0.24rem;
  width: 5.6rem;
  height: 4.54rem;
  background: url('@/assets/desktop_exercise/panel/仿真演练日志.png') no-repeat;
  background-size: 100%;
  .angle {
    position: absolute;
    width: 0.35rem;
    height: 0.35rem;
  }
  .top-left {
    margin: -0.1rem 0 0 -0.37rem;
    background: url('@/assets/desktop_exercise/panel/左上.png');
    background-size: 100%;
  }
  .bottom-left {
    margin: 4.28rem 0 0 -0.37rem;
    background: url('@/assets/desktop_exercise/panel/左下.png');
    background-size: 100%;
  }
  .top-right {
    margin: 0.42rem 0 0 5.1rem;
    background: url('@/assets/desktop_exercise/panel/右上.png');
    background-size: 100%;
  }
  .bottom-right {
    margin: 4.28rem 0 0 5.1rem;
    background: url('@/assets/desktop_exercise/panel/右下.png');
    background-size: 100%;
  }
  .title {
    margin: 0.12rem 0;
    font-family: PingFangSC-Regular;
    font-size: 0.24rem;
    color: #54f4fd;
    text-shadow: 0 0 0.02rem #54f4fd;
  }
  .btn {
    width: 0.82rem;
    margin-right: 0.16rem;
    background: initial;
    color: #f8b902;
    border: 1px solid #f8b902;
    box-shadow: inset 0 0 4px 0 rgba(248, 185, 2, 0.8);
  }
  .content {
    margin-top: 0.24rem;
    height: 3.2rem;
    overflow-y: scroll;
    .item {
      display: inline-flex;
      padding: 0.04rem 0 0.04rem 0.08rem;
      margin: 0.04rem 0 0.04rem -0.08rem;
      font-family: PingFangSC-Regular;
      font-size: 0.14rem;
      color: #e1fdff;
      cursor: pointer;
      .status {
        margin: 0 0.1rem;
      }
      .value {
        max-width: 2.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon {
        margin-left: 0.04rem;
        width: 0.24rem;
        height: 0.24rem;
      }
    }
  }
}
