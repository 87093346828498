.box {
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.2rem 10%;
  // text-align: center;
  // width: 50%;
  // margin: 5% auto;
  // justify-content: center;
  // align-items: center;
  // border: 1px solid rgba(84,244,253,.2);
  .header {
    height: max-content;
    color: white;
    padding-bottom: 0.1rem;
    .title {
      font-size: 0.3rem;
      line-height: 0.3rem;
    }
    .time {
      font-size: 0.1rem;
      line-height: 0.1rem;
    }
  }
  .content {
    float: left;
    // padding-left: 0.2rem;
    width: 80%;
    .brief {
      padding-top: 0.1rem;
      font-size: 0.2rem;
      line-height: 0.2rem;
    }
  }
  .moreVideoList {
    float: right;
    width: 20%;
    padding-left: 0.1rem;
    height: 100%;
    // border: 1px solid #54f4fd;
    .title {
      font-size: 0.24rem;
    }
  }
}
