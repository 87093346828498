.container {
  margin: 20px 20px;
  flex: auto;
  flex-direction: row;
  .viewModel {
    width: 1.6rem;
    height: 1.8rem;
  }
  .table {
    margin-top: 20px;
    :global {
      .ant-table {
        color: white;
        border: 1px solid white;
        border-radius: 2px;
        // background-color: rgba(230, 230, 242, 0.5);
      }

      .ant-table-small .ant-table-header {
        background: #4d90c7;
      }

      .ant-table-small .ant-table-thead > tr > th {
        color: white;
        background: #4d90c7;
        border-right: 1px solid white;
        font-size: 0.16rem;
      }
      .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0 -1px;
      }
      .ant-table-row-cell-last {
        border: none !important;
      }
      .ant-table-tbody {
        // background-color: rgba(230, 230, 242, 0.5);
        background: transparent;
      }
      // .ant-table-body > tr{
      //   color: white;
      //   border: none !important;
      // }
      .ant-table-tbody > tr > td {
        font-size: 0.15rem;
        border-top: 1px solid white;
        border-right: 1px solid white;
      }
      .ant-table-tbody > tr:hover {
        background: rgba(255, 255, 255, 0) !important;
        color: #163045;
      }
      // .ant-table-fixed-header .ant-table-scroll .ant-table-header{
      //   margin: 0;
      //   padding: 0;
      //   overflow: hidden !important;
      // }
      .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
        background: #163045;
      }

      .ant-table-bordered.ant-table-fixed-header
        .ant-table-scroll
        .ant-table-header::-webkit-scrollbar {
        border: none !important;
        // border-width: 1px 1px 0 0;
      }
      .ant-table-body {
        &::-webkit-scrollbar {
          height: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: #2a647a;
        }
        &::-webkit-scrollbar-track {
          border-radius: 0;
          background: #737372;
        }
      }

      .ant-table-bordered .ant-table-header > table,
      .ant-table-bordered .ant-table-body > table,
      .ant-table-bordered .ant-table-fixed-left table,
      .ant-table-bordered .ant-table-fixed-right table {
        border: none;
        border-color: gray;
      }
      .ant-table-bordered .ant-table-thead > tr > th {
        border-right: 1px solid gray;
        border-bottom: 1px solid gray;
      }
      .ant-table-bordered .ant-table-tbody > tr > td {
        border-right: 1px;
      }
      .ant-table-thead > tr > th {
        text-align: center;
      }
      .ant-table-bordered
        .ant-table-fixed-header
        .ant-table-scroll
        .ant-table-header.ant-table-hide-scrollbar
        .ant-table-thead
        > tr:only-child
        > th:last-child {
        border-right-color: gray;
      }
      .ant-table table {
        text-align: center;
      }
      .ant-table-tbody > tr > td {
        border-bottom: 0;
      }

      .ant-form-item {
        margin-bottom: 0 !important;
      }

      .ant-pagination {
        color: white !important;
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent !important;
      }

      .ant-pagination-item {
        background-color: transparent !important;
      }

      .ant-pagination-item-active {
        background-color: transparent !important;
      }

      .ant-divider-horizontal.ant-divider-with-text-center,
      .ant-divider-horizontal.ant-divider-with-text-left,
      .ant-divider-horizontal.ant-divider-with-text-right {
        color: white !important;
      }

      .ant-divider-horizontal.ant-divider-with-text-left::after {
        border-top: 1px solid gray;
      }

      .ant-pagination-item a {
        color: white !important;
      }
      .ant-select-selection {
        // border: ;
      }
      .ant-select {
        color: white;
      }
    }
  }
}
