.container {
  margin: 30px;

  .leftBox {
    display: inline-flex;
    flex-direction: column;
    width: 15%;
    margin-right: 0.16rem;
    margin-bottom: 0.16rem;
  }

  .rightBox {
    display: inline-flex;
    flex-direction: column;
    width: calc(85% - 0.16rem);
    margin-bottom: 0.16rem;
    background-size: 100% 100%;
    height: calc(100% - 0.16rem);
    border: 1px solid rgba(84, 244, 253, 0.2);
  }
  .panelList {
    :global {
      .ant-card {
        background: transparent;
      }
      // .ant-card-bordered {
      //   border: 1px solid #e8e8e8
      // }
      .ant-card-meta-title {
        color: white;
      }
    }
  }
}
